<template>
	<q-card style="width: 700px; max-width: 80vw;">
		<q-card-section>
			<div v-if="is_edit" class="text-h6">Edit webhook</div>
			<div v-else class="text-h6">Create webhook</div>
		</q-card-section>
		<q-card-section class="q-py-none">
			<q-select
				v-model="selected_dataset"
				:options="dataset_options"
				:error="has_select_dataset_error"
				:error-message="select_dataset_error_messag"
				:label="'Select dataset'"
			/>
			<q-input
				class="q-mb-lg"
				outlined
				v-model="friendly_name"
				:error="has_friendly_name_error"
				:error-message="friendly_name_error_message"
				label="Friendly Name"
			/>
			<q-input
				outlined
				v-model="webhook_url"
				:error="has_webhook_url_error"
				:error-message="webhook_url_error_message"
				label="Webhook Url"
			/>
		</q-card-section>
		<q-card-actions class="justify-end">
			<q-btn flat label="Cancel" color="primary" @click="$emit('close')"/>
			<q-btn flat label="Save" color="primary" @click="saveWebhook()"/>
		</q-card-actions>
	</q-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import MicrosoftTeamsApi from '@/services/api/MicrosoftTeams.js';

export default {
	name: 'CreateEditWebhookDialog',
	data() {
		return {
			webhook_url: '',
			friendly_name: '',
			selected_dataset: '',
			dataset_options: [],
			has_select_dataset_error: false,
			select_dataset_error_messag: '',
			has_webhook_url_error: false,
			webhook_url_error_message: '',
			has_friendly_name_error: false,
			friendly_name_error_message: '',
			is_edit: false,
		}
	},
	props: {
		webhook_to_edit: Object,
	},
	computed: {
		...mapGetters(['customer_groups']),
	},
	methods: {
		...mapActions(['getCustomerGroups']),
		resetErrors() {
			this.has_select_dataset_error = false
			this.select_dataset_error_messag = ''
			this.has_webhook_url_error = false
			this.webhook_url_error_message = ''
			this.has_friendly_name_error = false
			this.friendly_name_error_message = ''

		},
		checkErrors() {
			let has_error = false
			if (!this.webhook_url.length) {
				has_error = true
				this.has_webhook_url_error = true
				this.webhook_url_error_message = 'Please enter a webhook'
			}
			if (!this.friendly_name.length) {
				has_error = true
				this.has_friendly_name_error = true
				this.friendly_name_error_message = 'Please enter a friendly name for your webhook'
			}
			if (!this.selected_dataset) {
				has_error = true
				this.has_select_dataset_error = true
				this.select_dataset_error_messag = "Please select dataset"
			}
			return has_error
		},
		async saveWebhook() {
			this.resetErrors()
			let has_error = this.checkErrors()
			if (has_error) {
				return
			}

			this.$q.loading.show({delay: 400})

			let data = {}

			data['webhook_url'] = this.webhook_url
			data['friendly_name'] = this.friendly_name

			let response = ''

			if (!this.is_edit) {
				response = await MicrosoftTeamsApi.createTeamsWebhook(this.selected_dataset.id, data);
			} else {
				response = await MicrosoftTeamsApi.editTeamsWebhook(
					this.selected_dataset.id, this.webhook_to_edit.id, data
				);
			}

			if (response.error) {
				this.$q.notify({
					timeout: 6700,
					message: Object.values(response.error_message),
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				this.$q.loading.hide()
				return
			}
			this.$q.notify({
				timeout: 6700,
				message: response
			})

			this.$q.loading.hide()
			this.$emit('webhook-created', this.selected_dataset)
			this.$emit('close')
		}
	},
	async created() {
		await this.getCustomerGroups()
		this.dataset_options = this.customer_groups.map(el => {
			return {id: el.id, label: el.name}
		})
		if (this.webhook_to_edit) {
			this.is_edit = true
			this.webhook_url = this.webhook_to_edit['teams_webhook']
			this.friendly_name = this.webhook_to_edit['webhook_name']
			for (let cg of this.dataset_options) {
				if (cg.id == this.webhook_to_edit['customer_group']) {
					this.selected_dataset = cg
				}
			}
		}
	}
}
</script>
