<template>
	<q-card>
		<q-card-section class="row items-center">
			<span>Are you sure you want to delete <b>{{ webhook_to_delete.webhook_name }}</b>?</span>
		</q-card-section>
		<q-card-actions align="right">
			<q-btn flat label="Cancel" color="primary" @click="$emit('closeDialog')" />
			<q-btn flat label="Delete" color="primary" @click="deleteWebhook" />
		</q-card-actions>
	</q-card>
</template>
<script>
import MicrosoftTeamsApi from '@/services/api/MicrosoftTeams.js';
export default {
	name: 'DeleteWebhookDialog',
	props: {
		webhook_to_delete: Object
	},
	methods: {
		async deleteWebhook(){
			let response = await MicrosoftTeamsApi.deleteWebhook(
				this.webhook_to_delete.customer_group,
				this.webhook_to_delete.id
			)
			if (response.error) {
				this.$q.notify({
					timeout: 6700,
					message: response.error_message,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			} else {
				this.$q.notify({timeout: 6700, message: response})
			}
			this.$emit('closeDialog')
			this.$emit("deleteWebhook")
		}
	},
}
</script>
