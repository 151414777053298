<template>
	<q-card style="width: 700px; max-width: 80vw;">
		<q-card-section>
			<div class="text-h6">Send quick message</div>
		</q-card-section>
		<q-card-section class="q-py-none">
			<q-select
			multiple
				v-model="selected_webhooks"
				:options="webhooks"
				:error="has_selected_webhooks_error"
				:error-message="selected_webhooks_error_message"
				:option-label="(item) => item.webhook_name"
				:label="'Select Webhook'"
			/>
			<q-input
				outlined
				type="textarea"
				v-model="message_text"
				:error="has_message_text_error"
				:error-message="message_text_error_message"
				label="Message Text"
			/>
		</q-card-section>
		<q-card-actions class="justify-end">
			<q-btn flat label="Cancel" color="primary" @click="$emit('close')" />
			<q-btn flat label="Send" color="primary" @click="sendMessage()" />
		</q-card-actions>
	</q-card>
</template>

<script>
import { mapGetters } from 'vuex';
import MicrosoftTeamsApi from '@/services/api/MicrosoftTeams.js';
export default {
	name: 'SendMessageDialog',
	data(){
		return {
            message_text: '',
            has_message_text_error: false,
            message_text_error_message: '',
            webhooks: [],
            selected_webhooks: [],
            has_selected_webhooks_error: false,
            selected_webhooks_error_message: '',
		}
	},
	computed: {
		...mapGetters([ 'current_CG' ]),
	},
	methods: {
		resetErrors(){
			this.has_selected_webhooks_error = false
			this.has_message_text_error = false
			this.selected_webhooks_error_message = ''
			this.message_text_error_message = ''
		},
		checkErrors(){
			let has_error = false
			if (!this.selected_webhooks.length){
				has_error = true
				this.has_selected_webhooks_error = true
				this.selected_webhooks_error_message = 'Please select a webhook'
			}
			if (!this.message_text){
				has_error = true
				this.has_message_text_error = true
				this.message_text_error_message = "Please write a message"
			}
			return has_error
		},
        async sendMessage(){
			let data = {}
            this.resetErrors()
			let has_errors = this.checkErrors()
			if (has_errors){
				return
			}
			data['message'] = this.message_text
			data['webhooks_ids'] = Object.keys(this.selected_webhooks).map(k => this.selected_webhooks[k].id)
			this.$q.loading.show({
				delay: 400,
			})
			let response = await MicrosoftTeamsApi.sendQuickMessage(data)
			if (response.error){
				this.$q.notify({
					timeout: 6700,
					message: Object.values(response.error_message),
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
				this.$q.loading.hide()
				return
			}
			this.$q.notify({
				timeout: 6700,
				message: response
			})

			this.$q.loading.hide()
			this.$emit('close')
			this.$router.push({ name: "Teams Webhooks Reports" })
        }

	},
	async created(){
		this.webhooks = await MicrosoftTeamsApi.getMicrosoftTeamsWebhook(this.current_CG.customer_group.id, true)
	}
}
</script>
