<template>
	<div>
		<q-table
			:columns="columns"
			:data="webhooks"
			:loading="pending_results"
			row-key="webhook_name"
			binary-state-sort
			hide-pagination
			:rows-per-page-options="[0]"
		>
			<template v-slot:top>
				<div class="row full-width">
					<div class="col-md-6 col-12 flex justify-left items-center">
						<q-btn
							color="primary"
							label="Create Webhook"
							:style="$q.screen.lt.md ? 'max-width:100%; width:100%;' : ''"
							@click="add_webhook_modal=true"
						/>
					</div>
					<div class="col-md-6 col-12">
						<q-select
							v-model="selected_dataset"
							:options="dataset_options"
							:label="'Select dataset'"
							:error="has_dataset_error"
							:error-message="dataset_error_message"
							@input="selectedDataset"
						/>
					</div>
				</div>
			</template>
			<template v-slot:body-cell-actions='item'>
				<q-td>
					<q-btn
						color='dark'
						icon="o_edit"
						round
						flat
						@click="webhook_to_edit = item.row; show_edit_dialog=true"
					>
						<q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
							Edit webhook
						</q-tooltip>
					</q-btn>

					<q-btn
						color='dark'
						icon="o_delete"
						round
						flat
						@click="webhook_to_delete = item.row; show_delete_dialog=true"
					>
						<q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
							Delete webhook
						</q-tooltip>
					</q-btn>
				</q-td>
			</template>
		</q-table>
		<q-dialog v-model="show_delete_dialog">
			<delete-webhook-dialog
				:webhook_to_delete='webhook_to_delete'
				@deleteWebhook='getWebhoksList'
				@closeDialog='show_delete_dialog = false'
			/>
		</q-dialog>
		<q-dialog v-model="show_edit_dialog">
			<create-edit-webhook-dialog
				:webhook_to_edit='webhook_to_edit'
				@close='show_edit_dialog = false'
				@webhook-created="selectedDataset"
			/>
		</q-dialog>
		<div v-if="webhooks.length" class="field-fab-wrapper q-mt-md">
			<q-btn
				v-if="$q.screen.gt.sm"
				size="md"
				padding="md lg"
				color="primary"
				icon="send"
				label="Send Quick Message"
				rounded
				@click="send_message_dialog = true"
			/>
			<q-btn
				v-else
				color="primary"
				icon="send"
				size="lg"
				round
				@click="send_message_dialog = true"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Send Quick Message
				</q-tooltip>
			</q-btn>
		</div>
		<q-dialog v-model="add_webhook_modal">
			<create-edit-webhook-dialog
				@close='add_webhook_modal = false'
				@webhook-created="selectedDataset"
			/>
		</q-dialog>
		<q-dialog v-model="send_message_dialog">
			<send-message-dialog
				@close='send_message_dialog = false'
			/>
		</q-dialog>
	</div>
</template>
<script>
import MicrosoftTeamsApi from '@/services/api/MicrosoftTeams.js';
import DeleteWebhookDialog from "@/components/integrations/microsoft_teams/DeleteWebhookDialog.vue";
import CreateEditWebhookDialog from "@/components/integrations/microsoft_teams/CreateEditWebhookDialog.vue";
import SendMessageDialog from "@/components/integrations/microsoft_teams/SendMessageDialog.vue";
import { mapActions, mapGetters } from 'vuex';
export default {
	name: 'WebhookTeamsList',
	components: { DeleteWebhookDialog, CreateEditWebhookDialog, SendMessageDialog },
	data(){
		return {
			webhooks: [],
			pending_results: false,
			dataset_options: [],
			selected_dataset: '',
			columns: [
				{
					name: 'webhook_name',
					field: row => row.webhook_name,
					align: 'left',
					label: 'Friendly name',
					sortable: true,
				},
				{
					name: "actions",
					field: "icons",
					label: "Actions",
					align: "center"
				}
			],
			webhook_to_delete: {},
			webhook_to_edit: {},
			show_delete_dialog: false,
			show_edit_dialog: false,
			has_dataset_error: false,
			dataset_error_message: '',
			new_webhook_cg: {},
			add_webhook_modal: false,
			send_message_dialog: false,
		}
	},
	computed: {
		...mapGetters([ 'customer_groups' ]),
	},
	methods:{
		...mapActions(['getCustomerGroups']),
		async getWebhoksList(){
			this.has_dataset_error = false
			this.dataset_error_message = ''
			if (!this.selected_dataset){
				this.has_dataset_error = true
				this.dataset_error_message = 'Please select dataset'
				return
			}
 			this.webhooks = await MicrosoftTeamsApi.getMicrosoftTeamsWebhook(this.selected_dataset.id)
		},
		async selectedDataset(new_webhook_cg){
			if (new_webhook_cg){
				this.selected_dataset = new_webhook_cg
			}
			await this.getWebhoksList()
		},
	},
	async created(){
		this.$q.loading.show({
			delay: 400,
		})
		await this.getCustomerGroups()
		this.dataset_options = this.customer_groups.map(el => {return {id: el.id, label: el.name}})
		this.selected_dataset = this.dataset_options[0]
		this.pending_results = true
		await this.getWebhoksList()
		this.pending_results = false
		this.$q.loading.hide()
	},
}
</script>
